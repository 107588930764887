import Foundation from './inc/foundation';
import 'slick-carousel';
import { allSliders } from './inc/sliders';
import {
  watchSection,
  startCounter,
  addedSelect2,
  scrollToBlock,
  serializeFormData,
} from './inc/functions';
import './inc/history-slider';
import './inc/styling-upload-input-gravity-form';
import './inc/standorte-show-list';
import { setMatchHeights } from './inc/set-match-heights';
import 'jquery-match-height';
import 'select2';
import './blog';
import './hr-portal';

allSliders();
setMatchHeights();
$(document).foundation();

/**
 * Add `is-active` class to menu-icon button on Responsive menu toggle
 * And remove it on breakpoint change
 */
$(window)
  .on('toggled.zf.responsiveToggle', function () {
    $('.menu-icon').toggleClass('is-active');
    $('body').toggleClass('scroll-fixed');
    $('.header-main').toggleClass('mobile-open');
    $('.logo').toggleClass('hidden');
  })
  .on('changed.zf.mediaquery', function () {
    $('.menu-icon').removeClass('is-active');
    $('body').removeClass('scroll-fixed');
    $('.header-main').removeClass('mobile-open');
    $('.logo').removeClass('hidden');
  });

$(window).on('load resize', () => {
  const header = $('.header');
  header.css('--header-height', header.outerHeight() + 'px');
});

$('.header-menu > .menu-item').on('mouseenter', function () {
  if (!Foundation.MediaQuery.is('large')) return;

  const submenu = $(this).find('.sub-menu');
  $('.header-menu .sub-menu').removeClass('is-active');
  if (!submenu.length) return;
  submenu.addClass('is-active');
  $('.header-main').css('--submenu-height', submenu.outerHeight() + 'px');
  $('.header').one('mouseleave', function () {
    $('.header-menu .sub-menu').removeClass('is-active');
    $('.header-main').css('--submenu-height', '0');
  });
});

$('.header-menu .menu-item-has-children > .show-submenu').on('click', function (e) {
  if (Foundation.MediaQuery.is('large')) return;
  e.preventDefault();
  const isActive = $(this).hasClass('js-submenu-active');
  $('.header-menu .menu-item-has-children > .show-submenu').removeClass(
    'js-submenu-active'
  );
  $('.header-menu .sub-menu').slideUp();
  if (!isActive) {
    const submenu = $(this).next('.sub-menu');
    $(this).addClass('js-submenu-active');
    submenu.slideDown();
  }
});

/**
 * Close responsive menu on orientation change
 */
$(window).on('orientationchange', function () {
  setTimeout(function () {
    if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
      $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
    }
  }, 200);
});
$(function () {
  addedSelect2();
});
$(window).on('scroll load', function () {
  watchSection();
  startCounter();
});

$(document).on('gform_post_render', addedSelect2);

function changeTitleForAccordion() {
  let sections = $('.content_image_text');
  if (sections.length === 0) return;
  const svgIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.2071 9.96086C18.5976 10.3514 18.5976 10.9846 18.2071 11.3751L12.5 17.0822L6.79289 11.3751C6.40237 10.9846 6.40237 10.3514 6.79289 9.96086C7.18342 9.57034 7.81658 9.57034 8.20711 9.96086L12.5 14.2538L16.7929 9.96086C17.1834 9.57034 17.8166 9.57034 18.2071 9.96086Z" fill="#FF7F30"/></svg>`;

  sections.each(function (_, item) {
    const accordionParent = $(item).find('.accordion');
    const accordionItem = accordionParent.find('.accordion-item');

    accordionItem.each(function (_, li) {
      $(li).on('click', function (e) {
        backTitleNameIfNotActive(e);

        //change Title for active Accordion
        if ($(this).hasClass('is-active')) {
          const title = $(this).find('.accordion-title');
          title.html(title.attr('data-title-active') + ' ' + svgIcon);
        }
      });
    });

    //change Title for inactive Accordion
    function backTitleNameIfNotActive(e) {
      const accParent = $(e.target).closest('.accordion');
      const accordionItems = accParent.find('.accordion-item');
      accordionItems.each(function (_, item) {
        const title = $(item).find('.accordion-title');
        title.html(title.attr('data-title') + ' ' + svgIcon);
      });
    }
  });
}

changeTitleForAccordion();

function changeTitleForAccordion_big_five_section() {
  let sections = $('.content_big_five');
  if (sections.length === 0) return;
  const svgIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M13.2929 5.29289C13.6834 4.90237 14.3166 4.90237 14.7071 5.29289L21.4142 12L14.7071 18.7071C14.3166 19.0976 13.6834 19.0976 13.2929 18.7071C12.9024 18.3166 12.9024 17.6834 13.2929 17.2929L17.5858 13H4C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11H17.5858L13.2929 6.70711C12.9024 6.31658 12.9024 5.68342 13.2929 5.29289Z" fill="#161933"/></g></svg>`;

  sections.each(function (_, item) {
    const accordionParent = $(item).find('.accordion');
    const accordionItem = accordionParent.find('.accordion-item');

    accordionItem.on('click', function () {
      const title = $(this).find('.accordion-title');
      if ($(this).hasClass('is-active')) {
        title.html(title.attr('data-title-active') + ' ' + svgIcon);
      } else {
        title.html(title.attr('data-title') + ' ' + svgIcon);
      }
    });
  });
}

changeTitleForAccordion_big_five_section();

$('.footer-menu__title').on('click', function () {
  $(this).next().toggleClass('open');
  $(this).parent().toggleClass('open');
  $(this).closest('.footer-menu-item').toggleClass('open');

  $('.footer-menu__title').not(this).next().removeClass('open');
  $('.footer-menu__title').not(this).parent().removeClass('open');
});

//Smooth Scroll======= Down
$('a[href*="#"]').on('click', function (e) {
  const $this = $(this);

  if ($this.closest('[data-tabs]').length || $this.closest('[data-accordion]').length)
    return;

  const href = $this.attr('href');
  if (href.indexOf('#') === 0) {
    e.preventDefault();
    scrollToBlock(href);
  } else {
    let split = href.split('#');
    let location = window.location.href.replace('#', '');
    if (location === split[0]) {
      e.preventDefault();
      scrollToBlock('#' + split[1]);
    }
  }
});
let locationHash = window.location.hash;
if (locationHash) {
  window.location.hash = '';
  scrollToBlock(locationHash);
}

//Smooth Scroll======= Up

function showPopup_contentBigFive() {
  const openPopupElement = $('[data-popup="open_popup_column"]');
  if (openPopupElement.length === 0) return;
  $(document).on('click', function (e) {
    const targetElement = $(e.target);
    const activePopup = $('.popup.open');
    const body = $('body');

    //close popup
    if (activePopup.length > 0) {
      if (
        !targetElement.closest('.popup__content').length > 0 ||
        targetElement.closest('.popup__close').length > 0
      ) {
        activePopup.removeClass('open');
        body.removeClass('lock');
      }
    }

    //open popup
    if (targetElement.attr('data-popup') === 'open_popup_column') {
      const popupTarget = targetElement.find('.popup');
      popupTarget.addClass('open');
      body.addClass('lock');
    }
  });
}

showPopup_contentBigFive();

$('.video__placeholder').on('click', function () {
  const video = $(this).prev();
  const videoElem = video.closest('video');
  const videoIframe = video.closest('iframe');
  videoIframe.attr('src', videoIframe.attr('src') + '&autoplay=1');
  if (videoElem.length) {
    videoElem.get(0).play();
  }
  $(this).addClass('hide');
});

/* global ajax_obj */
$('.standorte-filter').on('submit', function (e) {
  e.preventDefault();
  const $this = $(this);
  const values = serializeFormData(this);

  $.ajax({
    url: ajax_obj.ajax_url,
    type: 'GET',
    data: {
      action: 'get_standorte',
      search: values.search,
    },
    success: function (resp) {
      $('.js-standorte-list-wrapper').html(resp.list_html);
      $('.js-standorte-cards-wrapper').html(resp.cards_html);
      $('.js-standorte-count').html(resp.standorte_count);
      $this.attr('data-current-search', values.search);
    },
  });
});

let currentPage = 1;
$(document).on('click', '.standorte-cards .pagination .page-numbers', function (e) {
  e.preventDefault();
  const $this = $(this);
  if ($this.hasClass('disabled') || $this.hasClass('current')) {
    return;
  }
  let currentSearch = $('.standorte-filter').attr('data-current-search');
  if (!currentSearch) {
    currentSearch = '';
  }
  let paged = currentPage;
  if ($this.hasClass('next')) {
    paged++;
  } else if ($this.hasClass('prev')) {
    paged--;
  } else {
    paged = parseInt($this.text());
  }

  $.ajax({
    url: ajax_obj.ajax_url,
    type: 'GET',
    data: {
      action: 'get_standorte',
      search: currentSearch,
      paged,
    },
    success: function (resp) {
      $('.js-standorte-cards-wrapper').html(resp.cards_html);
      scrollToBlock('.standorte-cards');
    },
  });
});

$(window).on('load resize', function () {
  $(document.documentElement).css('--app-height', window.innerHeight + 'px');
});

/* Close blocks on button click */
$('.js-expand-btn_top').on('click', function () {
  if (!$(this).hasClass('clicked')) {
    $(this).prev().addClass('open');
    // line before: $(this).prev().slideDown();
    $(this).addClass('clicked');
  } else {
    $(this).prev().removeClass('open');
    $(this).removeClass('clicked');
  }
});

$('.js-expand-btn_bottom').on('click', function () {
  if (!$(this).hasClass('clicked')) {
    $(this).next().addClass('open');
    $(this).addClass('clicked');
  } else {
    $(this).next().removeClass('open');

    $(this).removeClass('clicked');
  }
});

$(window).on('scroll load', function () {
  let stickyBlock = $('#general_info-sticky');
  let stickyLinks = $('#general_info-links-sticky');
  let stickyButtons = $('#sticky_buttons');

  if ($(window).scrollTop() >= 100 && $(window).width() >= 1024) {
    stickyBlock.addClass('sticky-active');
    stickyBlock.removeClass('sticky-disabled');

    stickyButtons.addClass('sticky-active');
    stickyButtons.removeClass('sticky-disabled');

    if ($(window).scrollTop() > 500) {
      stickyBlock.show();
    } else if ($(window).scrollTop() <= 499) {
      stickyBlock.hide();
    }
  } else if ($(window).width() < 1024 && $(window).scrollTop() >= 100) {
    stickyBlock.addClass('sticky-active');
    stickyBlock.removeClass('sticky-disabled');

    stickyLinks.addClass('sticky-active');
    stickyLinks.removeClass('sticky-disabled');

    stickyButtons.addClass('sticky-active');
    stickyButtons.removeClass('sticky-disabled');
  } else {
    stickyBlock.removeClass('sticky-active');
    stickyLinks.removeClass('sticky-active');
    stickyButtons.removeClass('sticky-active');
    stickyBlock.addClass('sticky-disabled');
    stickyLinks.addClass('sticky-disabled');
    stickyButtons.addClass('sticky-disabled');
  }
});

$(window).on('load resize', function () {
  $('.naf-more-wrapper').toggleClass('hide', Foundation.MediaQuery.is('large'));
  $('body').css('--vw', document.body.clientWidth + 'px');
});

$('.block-map__form input[name="postcode"]').on('keyup', function () {
  $(this)
    .closest('form')
    .find('button')
    .prop('disabled', $(this).val() === '');
});
