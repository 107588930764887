/* eslint-disable */
function stylingUploadInputGravityForm() {
  function rewriteUploadsDescription() {
    const inputParent = $('.gfield--type-fileupload');
    if (inputParent.length === 0) return;
    inputParent.each(function (_, item) {
      const descriptionSpanParent = $(item).find('.ginput_container_fileupload');
      const descriptionSpan = $(item).find('.gform_fileupload_rules');
      const descriptionText = descriptionSpan.text();
      const html = `<p class="ruls_description">${descriptionText
        .substring(descriptionText.lastIndexOf(',') + 1)
        .trim()}</p>`;
      descriptionSpanParent.append(html);
    });

    //Max. Dateigröße: 10 MB.
  }

  if ($('.gform-body').length === 0) return;
  rewriteUploadsDescription();

  // eslint-disable-next-line no-undef
  gform.addAction('gform_input_change', function (elem, formId, fieldId) {
    if (elem.type !== 'file') return false;
    let inputParent = $(`#field_${formId}_${fieldId}`);
    let maxFileSizeCanBeeLoad = Math.floor(
      Number(inputParent.find('input[type="hidden"]').val()) / 1000
    );
    let inputFile = inputParent.find('input[type="file"]');
    let fileName = inputFile[0].files[0].name;
    let fileSize = Math.floor(Number(inputFile[0].files[0].size) / 1000);

    addError();
    changeLabelName();

    function addError() {
      const rules = inputParent.find('.gform_fileupload_rules').text();
      const rulesDescription = inputParent.find('.ruls_description');

      if (!checkFile()) {
        inputParent.addClass('error');
        const html = `<p class="error_rules">${rules}</p>`;
        $(html).appendTo(rulesDescription);
      } else {
        inputParent.removeClass('error');
        rulesDescription.find('.error_rules').remove();
      }
    }

    function changeLabelName() {
      let label = inputParent.find('.gfield_label');
      let labelFirstText = '';

      if (checkFile()) {
        labelFirstText = label[0].innerText;
        inputParent.addClass('success');

        if (!label.attr('data-first-text')) {
          label.attr('data-first-text', labelFirstText);
        }

        const html = `
          <p class="file_name">${fileName}</p>
          <p class="file_size">${
            fileSize > 1000 ? (fileSize / 1000).toFixed(2) + ' Mb' : fileSize + 'KB'
          }</p>
        `;
        label.html(html);
      }
    }

    function checkFile() {
      let fileFormats = []; //takes all formats who can use
      let fileFormat = fileName.split('.').pop();
      takeFileFormats(fileFormats);

      return fileFormats.includes(fileFormat) && maxFileSizeCanBeeLoad > fileSize;
    }

    function takeFileFormats(arr) {
      const inputString = inputParent.find('.gform_fileupload_rules')[0].innerText;
      const words = inputString.split(' ');
      $.each(words, function (index, word) {
        if (word.endsWith(',')) {
          arr.push(word.replace(',', ''));
        }
      });
    }
  });

  function clearInputFile() {
    $(document).on('click', '.gfield--type-fileupload', function (e) {
      const parent = $(this);
      if (parent.hasClass('success')) {
        e.preventDefault();
        const inputFile = parent.find('input[type="file"]');
        const label = parent.find('label');
        inputFile.val('');
        parent.removeClass('success');
        label.text(label.attr('data-first-text'));
      }
    });
  }
  clearInputFile();

  //If validation error ================
  $(document).on('gform_post_render', function (_, formID) {
    if ($('.gform_validation_error').length === 0) return;

    const form = $(`#gform_${formID}`);
    const inputFile = form.find('input[type="file"]');
    if (inputFile.length === 0) return;

    inputFile.each(function (_, item) {
      const inputParent = $(item).closest('.gfield--type-fileupload');
      const inputPreview = inputParent.find(
        '.ginput_preview .gfield_fileupload_filename'
      );

      if (inputPreview.length === 0) return;

      const inputLabel = inputParent.find('.gfield_label');
      const labelFirstText = inputLabel[0].innerText;
      const inputPreviewText = inputPreview.text();

      inputLabel.attr('data-first-text', labelFirstText);
      inputLabel.text(inputPreviewText);
      inputParent.addClass('success');
    });

    rewriteUploadsDescription();
  });
}
stylingUploadInputGravityForm();
