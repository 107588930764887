export function setMatchHeights() {
  $('.grid_image_and_text .card__title').matchHeight();

  $('.post_grid .card__title').matchHeight();

  $('.content_list .list_description').matchHeight();

  $('.content_list .list_title').matchHeight();

  $('.list-wrapper').matchHeight();

  $('.accordion-item_title').matchHeight();

  $('.download_block .card__title').matchHeight();

  $('.post_grid_category .card .title').matchHeight();
}
