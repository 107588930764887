/* global ajax_obj */
class Blog {
  constructor() {
    this.categories = [];
    this.catButtons = $('.category-btn');
    this.addEventListeners();
    this.orderby = ajax_obj.order;
  }
  addEventListeners() {
    this.catButtons.on('click', (e) => {
      const $this = $(e.target);
      const cat = $this.attr('data-category-id');
      if ($this.hasClass('active')) {
        const idx = this.categories.indexOf(cat);
        if (idx > -1) {
          this.categories.splice(idx, 1);
        }
        $this.removeClass('active');
      } else {
        this.categories.push(cat);
        $this.addClass('active');
      }
      this.getPosts();
    });
  }

  getPosts() {
    const that = this;

    $.ajax({
      url: ajax_obj.ajax_url,
      type: 'GET',
      data: {
        action: 'get_posts',
        categories: that.categories,
        orderby: that.orderby,
      },
      success: function (resp) {
        $('.js-blog').html(resp);
        document.cookie = 'categories=' + that.categories.join(',');
      },
    });
  }
}

new Blog();
