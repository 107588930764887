import Foundation from './foundation';

function showAllList() {
  const block = $('.standorte-list__list');
  if (block.length === 0) return;

  const howManyItemsToShow = block.attr('data-items-to-show');
  const listItems = block.find('.standorte-list__list-item');

  if (listItems.length > howManyItemsToShow) {
    const showMoreButton = $('.open_list');
    const svg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd"d="M13.2929 5.29289C13.6834 4.90237 14.3166 4.90237 14.7071 5.29289L21.4142 12L14.7071 18.7071C14.3166 19.0976 13.6834 19.0976 13.2929 18.7071C12.9024 18.3166 12.9024 17.6834 13.2929 17.2929L17.5858 13H4C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11H17.5858L13.2929 6.70711C12.9024 6.31658 12.9024 5.68342 13.2929 5.29289Z" fill="#161933"/></g></svg>`;

    //set height to block
    $(window).on('load resize', function () {
      if (Foundation.MediaQuery.is('small down')) {
        const initialHeight = $('.standorte-list__list_body').outerHeight(true);
        let maxHeight = 0;

        for (let i = 0; i < howManyItemsToShow; i++) {
          maxHeight += $(listItems[i]).outerHeight(true);
        }

        block.height(maxHeight);
        block.attr('data-initial-height', initialHeight);
        block.attr('data-max-height', maxHeight);
      } else {
        block.height(`100%`);
        block.attr('data-initial-height', `100%`);
        block.attr('data-max-height', `100%`);

        if (block.hasClass('is-active')) {
          block.removeClass('is-active');
          showMoreButton.text(showMoreButton.attr('data-show-more')).append(svg);
        }
      }
    });

    //toggle open/hidden
    showMoreButton.on('click', function () {
      const $this = $(this);
      block.toggleClass(function () {
        const listWrapper = $(this);
        const showMoreText = $this.attr('data-show-more');
        const showLessText = $this.attr('data-show-less');
        if ($(this).hasClass('is-active')) {
          listWrapper.animate({ height: listWrapper.attr('data-max-height') }, 500);
          $this.text(showMoreText).append(svg);
        } else {
          listWrapper.animate({ height: listWrapper.attr('data-initial-height') }, 500);
          $this.text(showLessText).append(svg);
        }

        return 'is-active';
      });
    });
  }
}
showAllList();
