import Foundation from './foundation';

$.fn.isInViewport = function () {
  if ($(this).length) {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop() - 200;
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  }
  return 0;
};

$.fn.isInViewportNoOffset = function () {
  if ($(this).length) {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop() - 200;
    var viewportBottom = viewportTop + $(window).height() - 100;

    return elementBottom > viewportTop && elementTop < viewportBottom;
  }
  return 0;
};

export function initSliderOnMobile(
  selector,
  options = {},
  breakpoint = 'medium',
  setArrows = false,
  setDots = false
) {
  $(selector).each(function (idx, item) {
    $(window).on('load changed.zf.mediaquery', function () {
      let isSlider = $(item).hasClass('slick-initialized');
      if (Foundation.MediaQuery.is(breakpoint)) {
        if (isSlider) {
          $(item).slick('unslick');
        }
      } else if (!isSlider) {
        if (setArrows) {
          $.extend(options, {
            prevArrow: $(item).closest('section').find('.slick-prev'),
            nextArrow: $(item).closest('section').find('.slick-next'),
          });
        }

        if (setDots) {
          $.extend(options, {
            appendDots: $(item).closest('section').find('.slider__dots'),
          });
        }

        $(item).slick(options);
      }
    });
  });
}

export function watchSection() {
  const sections = $('[data-watch]');
  if (!sections) return;
  sections.each(function (_, item) {
    if ($(item).isInViewport()) {
      if ($(item).hasClass('see_section')) return;

      $(item).addClass('see_section');
    } else {
      $(item).removeClass('see_section');
    }
  });
}

export function startCounter() {
  $('[data-counter]').each(function (i, section) {
    if ($(section).isInViewport()) {
      if ($(section).hasClass('count-up')) return;

      $(section).addClass('count-up');
      $(section)
        .find('.counter-item_number')
        .each(function (i, counter) {
          $(counter).countUp();
        });
    } else {
      $(section).removeClass('count-up');
    }
  });
}

$.fn.countUp = function () {
  let num = $(this).attr('data-initial');
  let decimal = 0;

  if (num === 0 || isNaN(parseFloat(num))) {
    return;
  }
  num = num.replace(',', '.');
  const split = num.toString().split('.');

  if (split[1]) {
    decimal = split[1].length;
  }

  $(this)
    .prop('counter', 0.0)
    .animate(
      {
        counter: num,
      },
      {
        duration: 500,
        step: function (now) {
          $(this).text(parseFloat(now).toFixed(decimal));
        },
        complete: function () {
          $(this).text(
            $(this)
              .text()
              .replace(/(.*[.])/, '$1,')
              .replace(/\./g, '')
          );
          $(this).stop(true, true);
        },
      }
    );
};

export function addedSelect2() {
  const selects = $('select');
  if (selects.length === 0) return;
  selects.each(function (_, item) {
    $(item).select2({
      minimumResultsForSearch: Infinity, //remove search field
    });
    //remove duplicate select placeholder on options
    let firstOption = $(item).find('option:first');
    firstOption.prop('disabled', true);
  });
}

export function scrollToBlock(id) {
  const block = $(id);

  if (!block.length) return;

  let offset = $('.header').outerHeight();
  if (!offset) {
    offset = 0;
  }

  setTimeout(function () {
    $('html,body').animate(
      {
        scrollTop: $(id).offset().top - (offset + 50),
      },
      400
    );
  }, 500);
}

export function serializeFormData(form) {
  const values = {};

  $.each($(form).serializeArray(), (i, field) => {
    values[field.name] = field.value;
  });

  return values;
}

export function slickAnimateDots(slider, dotsToShow, maxBreakpoint = 0) {
  let currentTransform = 0;
  let dotWidth = 0;
  let activeDotWidth = 0;
  let margin = 0;
  let maxTransform = 0;

  slider.on('init breakpoint', function (e, slick) {
    const dotsUl = slick.$dots;
    if (!dotsUl) return;

    const dotsWrapper = dotsUl.closest('.slider__dots');
    if (maxBreakpoint && window.innerWidth > maxBreakpoint) {
      dotsUl.css('transform', 'none');
      dotsWrapper.css('width', 'auto');
      currentTransform = 0;
      return;
    }

    dotsUl.css('position', 'relative');
    const activeDot = dotsUl.find('.slick-active');
    const dots = dotsUl.find('li');

    dotWidth = dotsUl.find('li:not(.slick-active)').first().width();
    margin = parseInt(dots.css('marginRight'));
    activeDotWidth = activeDot.width();
    maxTransform =
      dotsWrapper.get(0).scrollWidth -
      (dotsToShow - 1) * dotWidth -
      activeDotWidth -
      margin * (dotsToShow - 1);

    const activeDotOffset = activeDot.position().left;
    if (activeDotOffset > maxTransform) {
      currentTransform = -maxTransform;
    } else {
      currentTransform = -activeDotOffset;
    }

    dotsUl.css('transition', `transform 0.3s ease-in-out`);
    dotsUl.css('transform', `translateX(${currentTransform}px)`);

    const dotsViewportWidth =
      activeDotWidth + (dotsToShow - 1) * dotWidth + (dotsToShow - 1) * margin;

    dotsWrapper.css({
      width: dotsViewportWidth + 'px',
      overflow: 'hidden',
    });
  });

  slider.on('beforeChange', function (e, slick, currentSlide, nextSlide) {
    if (maxBreakpoint && window.innerWidth > maxBreakpoint) {
      return;
    }
    const dotsUl = slick.$dots;
    const direction = currentSlide - nextSlide > 0 ? 1 : -1;
    currentTransform += direction * (dotWidth + margin);
    if (currentTransform < -maxTransform || nextSlide + 1 === slick.slideCount) {
      currentTransform = -maxTransform;
    }
    if (currentTransform > 0 || nextSlide === 0) {
      currentTransform = 0;
    }
    dotsUl.css('transform', `translateX(${currentTransform}px)`);
  });
}
