// /* eslint-disable */
import { initSliderOnMobile, slickAnimateDots } from './functions';
// import Foundation from './foundation';

const sliderArgs = {
  arrows: true,
  dots: true,
  infinite: true,
  variableWidth: false,
};

export function allSliders() {
  const logosSliderWithDecorArgs = {
    arrows: true,
    dots: true,
    infinite: true,
    variableWidth: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const logosSliderWithOutDecorArgs = {
    arrows: true,
    dots: true,
    infinite: true,
    variableWidth: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const trust_slider_args = {
    arrows: true,
    dots: true,
    infinite: true,
    slidesToShow: 4,
    rows: 0,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  $('.testimonials-and-content__items-slider').each(initTestimonialsSlider);
  $('.testimonials-and-content__side-slider-items').each(initTestimonialsSlider);
  $('.testimonials-video-slider-items').each(initTestimonialsSlider);
  $('.testimonials-and-tabs__items-slider').each(initTestimonialsSlider);

  initSliderOnMobile('.grid_image_and_text__slider', sliderArgs, 'large', true, true);

  initSliderOnMobile(
    '.logos-slider.with-decor .logos-slider__wrapper',
    logosSliderWithDecorArgs,
    '',
    true,
    true
  );

  initSliderOnMobile(
    '.logos-slider.no-decor .logos-slider__wrapper',
    logosSliderWithOutDecorArgs,
    '',
    true,
    true
  );

  initSliderOnMobile('.process_type_two__slider', sliderArgs, 'large', true, true);

  initSliderOnMobile('.trust_slider .slider', trust_slider_args, '', true, true);

  testimonialPersonSlider();

  adaptiveDots();
}

function initTestimonialsSlider(idx, item) {
  const $this = $(item);
  const controlsWrap = $this.next('.slider_controls');
  $this.slick(
    $.extend(sliderArgs, {
      prevArrow: controlsWrap.find('button.slick-prev'),
      nextArrow: controlsWrap.find('button.slick-next'),
      appendDots: controlsWrap.find('.slider__dots'),
    })
  );
}

function testimonialPersonSlider() {
  const sections = $('.testimonial_person');
  if (sections.length === 0) return;
  sections.each(function (_, section) {
    const slider = $(section).find('.testimonial_person__slider');
    slickAnimateDots(slider, 4);
    slider.slick({
      arrows: true,
      dots: true,
      infinite: true,
      variableWidth: false,
      prevArrow: slider.closest('section').find('.slick-prev'),
      nextArrow: slider.closest('section').find('.slick-next'),
      appendDots: slider.closest('section').find('.slider__dots'),
    });
  });
}

function adaptiveDots() {
  const sections = $('.voices_from_the_press');
  if (sections.length === 0) return;
  sections.each(function (_, section) {
    let slider = $(section).find('.voices_from_the_press__slider');
    // let once = false;
    // slider.on('init breakpoint', function (e, slick) {
    //   const howManyDotsToShow = 5;
    //   const marginRightBetweenDots = 10;
    //   const dotsViewport = $(section).find('.slider__dots');
    //   const dotsUl = slick.$dots;
    //   const activeDot = $(section).find('ul .slick-active');
    //   const activeDotsWidth = activeDot.width();
    //   const notActiveDotsWidth = $(section).find('ul li:not(.slick-active)').width();
    //   const dots = dotsUl.find('li');
    //   // const dotsUlWidth = dotsUl.width();
    //   const dotsUlWidth =
    //     activeDotsWidth +
    //     (dots.length - 1) * notActiveDotsWidth +
    //     (dots.length - 1) * marginRightBetweenDots;
    //   const dotsViewportWidth =
    //     activeDotsWidth +
    //     (howManyDotsToShow - 1) * notActiveDotsWidth +
    //     howManyDotsToShow * marginRightBetweenDots;
    //   let moveDotsUl = 0;
    //   let currSlider = 0;
    //
    //   if ($(slick.activeBreakpoint <= 641)) {
    //     dotsUl.css('position', 'relative');
    //
    //     dotsViewport.css({
    //       width: `${dotsViewportWidth}px`,
    //       overflow: 'hidden',
    //     });
    //
    //     slider.on('afterChange', function (event, slick, currentSlide) {
    //       if (currentSlide > currSlider) {
    //         moveDotsUl -= notActiveDotsWidth + marginRightBetweenDots;
    //         dotsUl.css('transform', `translateX(${moveDotsUl}px)`);
    //       }
    //
    //       if (currentSlide < currSlider) {
    //         moveDotsUl += notActiveDotsWidth + marginRightBetweenDots;
    //         dotsUl.css('transform', `translateX(${moveDotsUl}px)`);
    //       }
    //
    //       if (currentSlide + 1 === dots.length) {
    //         //if last slide
    //         dotsUl.css(
    //           'transform',
    //           `translateX(-${
    //             dotsUlWidth - activeDotsWidth * 2 - marginRightBetweenDots
    //           }px)`
    //         );
    //         moveDotsUl = -(dotsUlWidth - activeDotsWidth * 2 - marginRightBetweenDots);
    //       }
    //
    //       if (currentSlide === 0) {
    //         //if first slide
    //         dotsUl.css('transform', `none`);
    //         moveDotsUl = 0;
    //       }
    //
    //       //if back from big breakpoint
    //       if (once === true) {
    //         dotsUl.css('transform', `translateX(-${activeDot.position().left}px)`);
    //         moveDotsUl = -activeDot.position().left;
    //         once = false;
    //       }
    //
    //       currSlider = currentSlide;
    //     });
    //   }
    //
    //   if (slick.activeBreakpoint > 641 || slick.activeBreakpoint == null) {
    //     $(section).find('.slider__dots').css('width', 'auto');
    //     slick.$dots.css('transform', 'none');
    //     slider.off('afterChange');
    //     once = true;
    //   }
    // });

    slickAnimateDots(slider, 5, 641);

    //init slider
    slider.slick({
      arrows: true,
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      speed: 600,
      prevArrow: $(section).find('.slick-prev'),
      nextArrow: $(section).find('.slick-next'),
      appendDots: $(section).find('.slider__dots'),
      responsive: [
        {
          breakpoint: 1250,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 940,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 641,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  });
}
