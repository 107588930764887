/* global ajax_obj */
import { serializeFormData, scrollToBlock } from './inc/functions';

(function () {
  if (!$('body').hasClass('page-template-template-hrportal')) return;

  const categories = [];
  const tags = [];
  let search = '';
  let currentPage = 1;

  // let isLoading = false;

  $(document).on('click', '.hr-categories__category', function (e) {
    e.preventDefault();
    const $this = $(this);
    const currentId = $this.attr('data-id');

    if (!currentId) {
      categories.length = 0;
    } else {
      const idx = categories.indexOf(currentId);
      if (idx > -1) {
        categories.splice(idx, 1);
      } else {
        categories.push(currentId);
      }
    }

    getPosts();
  });

  $(document).on('click', '.hr-tags__tag', function (e) {
    e.preventDefault();
    const $this = $(this);
    const currentId = $this.attr('data-id');

    if (!currentId) {
      tags.length = 0;
    } else {
      const idx = tags.indexOf(currentId);
      if (idx > -1) {
        tags.splice(idx, 1);
      } else {
        tags.push(currentId);
      }
    }

    getPosts();
  });

  $(document).on('click', '.pagination a', function (e) {
    e.preventDefault();
    const $this = $(this);
    let page;

    if ($this.hasClass('disabled')) {
      return;
    }

    if ($this.hasClass('prev')) {
      page = currentPage - 1;
    } else if ($this.hasClass('next')) {
      page = currentPage + 1;
    } else {
      page = parseInt($(this).text());
    }

    getPosts(page);
  });

  $(document).on('reset', '#searchform', function () {
    resetFilters();
    search = '';
    getPosts(1, true);
  });

  $(document).on('submit', '#searchform', function (e) {
    e.preventDefault();
    resetFilters();
    const formData = serializeFormData(this);
    search = formData.s;

    getPosts(1, true);
  });

  $(document).on('click', '.hr-tags__toggler', function () {
    const $this = $(this);
    $('.toggle-hidden').toggleClass('hide');
    $this.toggleClass(function () {
      if ($this.hasClass('active')) {
        $this.text($this.attr('data-show-text'));
      } else {
        $this.text($this.attr('data-hide-text'));
      }
      return 'active';
    });
  });

  function getPosts(page = 1, isSearch = false) {
    // if (isLoading) return;

    $.ajax({
      url: ajax_obj.ajax_url,
      type: 'GET',
      data: {
        action: 'get_hr_portal_posts',
        s: search,
        categories,
        tags,
        page,
      },
      beforeSend: function () {
        $('.hr-ajax-wrapper').addClass('loading');
      },
      success: function (resp) {
        $('.hr-ajax-wrapper').html(resp.html);
        $('.post-count').text(resp.postCount);
        currentPage = page;
        if (isSearch !== false) {
          $('.hr-categories').html(resp.categoriesHtml);
          $('.hr-tags').html(resp.tagsHtml);
        } else {
          toggleActiveStates();
        }
      },
      complete: function () {
        $('.hr-ajax-wrapper').removeClass('loading');
        scrollToBlock('.hr-posts__title');
      },
    });
  }

  function resetFilters() {
    categories.length = 0;
    tags.length = 0;
  }

  function toggleActiveStates() {
    const activeClass = 'is-active';
    $('.hr-categories__category, .hr-tags__tag').removeClass(activeClass);

    categories.forEach((catId) => {
      $(`.hr-categories__category[data-id="${catId}"]`).addClass(activeClass);
    });

    if (categories.length === 0) {
      $('.hr-categories__category:first').addClass(activeClass);
    }

    tags.forEach((tagId) => {
      $(`.hr-tags__tag[data-id="${tagId}"]`).addClass(activeClass);
    });
    if (tags.length === 0) {
      $('.hr-tags__tag:first').addClass(activeClass);
    }
  }
})();
